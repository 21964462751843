/* App.css */
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  background-color: #0d1117; /* GitHub dark background */
  color: #c9d1d9; /* GitHub dark text color */
  line-height: 1.6;
}

a {
  color: #58a6ff; /* GitHub dark blue link color */
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

button {
  background-color: #238636; /* GitHub dark green button background */
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  margin: 1rem 0;
  border-radius: 6px;
  cursor: pointer;
  font-size: 1rem;
}

button:hover {
  background-color: #2ea043; /* Lighter green on hover */
}

/* Utility classes for padding and margin */
.p-1 { padding: 1rem; }
.mt-1 { margin-top: 1rem; }

/* Flexbox utility classes */
.flex {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-column {
  flex-direction: column;
}

.space-between {
  justify-content: space-between;
}

.space-around {
  justify-content: space-around;
}

/* Grid utility classes */
.grid {
  display: grid;
  grid-gap: 1rem;
}

/* Other utility classes */
.text-center {
  text-align: center;
}

/* Responsive typography */
h1 {
  font-size: 2rem;
}

h2 {
  font-size: 1.75rem;
}

h3 {
  font-size: 1.5rem;
}

@media (max-width: 768px) {
  h1 {
    font-size: 1.75rem;
  }

  h2 {
    font-size: 1.5rem;
  }

  h3 {
    font-size: 1.25rem;
  }
}

/* Responsive layout */
@media (max-width: 768px) {
  .flex {
    flex-direction: column;
  }
}

