/* Blog.css */
.services-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    padding: 4rem 1rem;
    background-color: #0d1117;
    color: #c9d1d9;
}

/* Make the service sections wider for blog posts */
.service {
    width: 90%;
    /* Increased from 80% */
    max-width: 1200px;
    /* Increased from 800px */
    background-color: #161b22;
    margin-bottom: 3rem;
    padding: 3rem;
    /* Increased padding */
    border-radius: 8px;
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.6);
}

/* Blog list specific styles */
.blog-title {
    color: #58a6ff;
    font-size: 2.5rem;
    margin-bottom: 2rem;
    width: 90%;
    max-width: 1200px;
}

/* Blog post specific styles */
.post-title {
    color: #58a6ff;
    font-size: 2.8rem;
    margin-bottom: 1rem;
    line-height: 1.2;
}

.post-date {
    color: #8b949e;
    font-size: 1.1rem;
    margin-bottom: 2rem;
    font-style: italic;
}

.post-description {
    color: #8b949e;
    font-size: 1.2rem;
    line-height: 1.6;
    margin: 1.5rem auto;
    max-width: 800px;
}

.back-link {
    display: inline-block;
    color: #58a6ff;
    text-decoration: none;
    margin-bottom: 2rem;
    font-size: 1.1rem;
}

.back-link:hover {
    text-decoration: underline;
}

/* Blog content styles */
.blog-content {
    text-align: left;
    margin-top: 3rem;
    border-top: 1px solid #30363d;
    padding-top: 3rem;
}

/* Markdown content styles */
.blog-content h1 {
    color: #58a6ff;
    font-size: 2.5rem;
    margin: 2rem 0 1.5rem;
    line-height: 1.2;
}

.blog-content h2 {
    color: #58a6ff;
    font-size: 2rem;
    margin: 2rem 0 1rem;
    line-height: 1.3;
}

.blog-content h3 {
    color: #58a6ff;
    font-size: 1.7rem;
    margin: 1.8rem 0 1rem;
}

.blog-content p {
    color: #8b949e;
    font-size: 1.1rem;
    line-height: 1.8;
    margin: 1.5rem 0;
}

.blog-content ul,
.blog-content ol {
    color: #8b949e;
    margin: 1.5rem 0 1.5rem 2rem;
    font-size: 1.1rem;
    line-height: 1.8;
}

.blog-content li {
    margin: 0.8rem 0;
}

.blog-content a {
    color: #58a6ff;
    text-decoration: none;
}

.blog-content a:hover {
    text-decoration: underline;
}

.blog-content blockquote {
    border-left: 4px solid #30363d;
    margin: 2rem 0;
    padding-left: 2rem;
    color: #8b949e;
    font-style: italic;
}

.blog-content code {
    background-color: #21262d;
    padding: 0.2em 0.4em;
    border-radius: 3px;
    font-family: monospace;
    font-size: 0.9em;
}

.blog-content pre {
    background-color: #21262d;
    padding: 1.5rem;
    border-radius: 8px;
    overflow-x: auto;
    margin: 2rem 0;
}

.blog-content pre code {
    background-color: transparent;
    padding: 0;
    font-size: 1rem;
}

.blog-content img {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
    margin: 2rem 0;
}

.blog-content hr {
    border: none;
    border-top: 1px solid #30363d;
    margin: 3rem 0;
}

.blog-content table {
    width: 100%;
    border-collapse: collapse;
    margin: 2rem 0;
    font-size: 1.1rem;
}

.blog-content th,
.blog-content td {
    border: 1px solid #30363d;
    padding: 1rem;
    text-align: left;
}

.blog-content th {
    background-color: #21262d;
    font-weight: bold;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .service {
        width: 95%;
        padding: 2rem;
    }

    .post-title {
        font-size: 2.2rem;
    }

    .blog-content h1 {
        font-size: 2rem;
    }

    .blog-content h2 {
        font-size: 1.8rem;
    }

    .blog-content h3 {
        font-size: 1.5rem;
    }

    .blog-content p,
    .blog-content li {
        font-size: 1rem;
    }
}
