.site-header {
  background-color: #0d1117; /* GitHub dark header color */
  color: #c9d1d9; /* GitHub dark text color */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem 2rem;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
}

.site-header .logo {
  font-size: 1.5rem;
  color: #58a6ff; /* GitHub dark blue color */
  position: absolute;
  left: 2rem;
}

.site-header .main-nav ul {
  list-style: none;
  position: relative;
  flex: 1;
  display: flex;
  gap: 1rem;
  margin: 0;
  padding: 0;
}

.site-header .main-nav a {
  color: #c9d1d9;
  text-decoration: none;
  padding: 0.5rem 1rem;
  transition: color 0.3s;
}

.site-header .main-nav a:hover {
  color: #58a6ff;
}
