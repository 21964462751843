.landing-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding-top: 3.5rem; /* Offset for the fixed header */
  background-color: #0d1117; /* GitHub dark main background color */
  color: #c9d1d9; /* GitHub dark text color */
  text-align: center;
  padding-top: 15rem;
}
.landing-page .intro h1 {
  color: #58a6ff; /* GitHub dark blue color */
  font-size: 3rem;
  margin-bottom: 0.5rem;
}

.landing-page .intro h2 {
  color: #8b949e; /* GitHub dark secondary text color */
  font-size: 2rem;
  font-weight: normal;
  margin-bottom: 1.5rem;
}

.landing-page .intro h3 {
  color: #58a6ff; /* GitHub dark blue color */
  font-size: 3rem;
  margin-bottom: 0.5rem;
  padding: 5rem 5rem;
}

.landing-page .intro p {
  color: #8b949e; /* GitHub dark secondary text color */
  max-width: 600px;
  margin-bottom: 2rem;
}

.landing-page button {
  background-color: #21262d; /* GitHub dark button background */
  color: #c9d1d9;
  border: none;
  padding: 0.75rem 1.5rem;
  cursor: pointer;
  transition: background-color 0.3s;
}

.landing-page button:hover {
  background-color: #30363d; /* GitHub dark button hover */
}

.landing-page .intro {
    /* Your existing styles... */
}

.landing-page .intro .portfolio-link {
    margin-top: 80px; /* Adjust as needed for visual separation */
    text-align: center; /* Center the content */
}

.landing-page .intro .portfolio-link .arrow {
    display: block; /* Makes the arrow appear on a new line */
    margin-top: 10px; /* Space between text and arrow */
    font-size: 24px; /* Adjust size of the arrow */
}

.landing-page .social-links {
  display: flex;
  justify-content: center;
  gap: 20px; /* Space between icons */
  margin-top: 2rem; /* Space above the social links section */
}

.landing-page .social-links a {
  display: inline-block;
  transition: transform 0.3s ease-in-out; /* Smooth transition for hover effect */
}

.landing-page .social-links img {
  width: 40px; /* Icon size */
  height: 40px; /* Icon size */
  filter: grayscale(100%) brightness(90%); /* Makes icons white and slightly dim */
  transition: filter 0.3s ease-in-out; /* Smooth transition for hover effect */
}

.landing-page .social-links a:hover img {
  filter: grayscale(0%) brightness(130%); /* Icon color change on hover */
  transform: scale(1.1); /* Makes the icon slightly larger on hover */
}
