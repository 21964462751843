/* Styles for the overall services page */
.services-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    padding: 4rem 1rem;
    background-color: #0d1117; /* GitHub dark main background color */
    color: #c9d1d9; /* GitHub dark text color */
    text-align: center;
}

/* Styles for individual service sections */
.service {
    width: 80%; /* Adjust width as needed */
    max-width: 800px; /* Maximum width of the service box */
    background-color: #161b22; /* Slightly lighter background for the service box */
    margin-bottom: 3rem;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.6); /* Box shadow for depth */
}

/* Styles for service titles */
.service h2 {
    color: #58a6ff; /* GitHub dark blue color for titles */
    font-size: 2rem;
    margin-bottom: 1rem;
}

/* Styles for service descriptions */
.service p {
    color: #8b949e; /* GitHub dark secondary text color */
    max-width: 600px; /* Maximum width of the text */
    margin: 0 auto 1.5rem; /* Center the text block and add space below */
}
/* Services.css - Updated with new styles for form elements */
/* Services.css - Updated with new styles for form integration */

/* ... other existing styles ... */

/* Styles for the contact form container */
.services-page .contact-container {
    background-color: #161b22; /* Consistent with service sections */
    border-radius: 8px; /* Rounded corners for the container */
    padding: 2rem; /* Consistent padding within the container */
    margin: 2rem auto; /* Consistent spacing around the container */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5); /* Subtle shadow for depth */
    width: 80%; /* Consistent width with service sections */
    max-width: 800px; /* Maximum width to align with service sections */
}
.services-page .contact-container .submit-btn { /* Updated selector */
    padding: 12px 20px; /* Padding to match input fields */
    background-color: #21262d; /* Dark background for the button */
    color: #58a6ff; /* Text color to stand out and match the theme */
    border: 1px solid #30363d; /* Border to match input fields */
    border-radius: 4px; /* Rounded corners to match input fields */
    cursor: pointer; /* Cursor change to indicate interactiveness */
    transition: background-color 0.3s, color 0.3s; /* Smooth transition for hover effects */
}

.services-page .contact-form label {
    color: #c9d1d9; /* Light color for labels to stand out on the dark background */
    display: block; /* Ensure the label is on a new line */
    margin-bottom: 0.5rem; /* Space between label and input field */
}

/* Styles for the submit button to match the theme */
.services-page .contact-form button {
    padding: 12px 20px; /* Padding to match input fields */
    background-color: #21262d; /* Dark background for the button */
    color: #58a6ff; /* Text color to stand out and match the theme */
    border: 1px solid #30363d; /* Border to match input fields */
    border-radius: 4px; /* Rounded corners to match input fields */
    cursor: pointer; /* Cursor change to indicate interactiveness */
    transition: background-color 0.3s, color 0.3s; /* Smooth transition for hover effects */
}

.services-page .contact-form button:hover {
    background-color: #58a6ff; /* Color change on hover for button */
    color: #0d1117; /* Text color change on hover for button */
}

/* Add focus styles for input and textarea to enhance the user experience */
.services-page .contact-form input:focus,
.services-page .contact-form textarea:focus {
    border-color: #58a6ff; /* Border color to match the hover state of the button */
    outline: none; /* Removes the default focus outline */
    box-shadow: 0 0 0 2px rgba(88, 166, 255, 0.3); /* Adds a glow effect on focus */
}

