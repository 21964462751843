/* Resume.css */

.resume-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 50px; /* Distance from the top */
    background-color: #0d1117; /* GitHub dark main background color */
    color: #c9d1d9; /* GitHub dark text color */
}

.language-switcher {
    margin-bottom: 50px; /* Space below the language switcher */
}

.language-switcher button {
    background-color: #21262d; /* GitHub dark button background */
    color: #c9d1d9;
    border: none;
    padding: 0.75rem 1.5rem;
    cursor: pointer;
    transition: background-color 0.3s;
}

.language-switcher button:hover {
    background-color: #30363d; /* GitHub dark button hover */
}

.resume-content {
    width: calc(80%); /* Width resembling A4 paper */
    min-height: calc(297mm * 1px); /* Height resembling A4 paper */
    text-align: left;
    background-color: #161b22; /* Slightly lighter background */
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 8px 24px rgba(0,0,0,0.6);
    margin-bottom: 50px; /* Space above the download button */
}

.download-resume {
    margin-bottom: 50px; /* Additional bottom margin */
}

.download-resume a {
    display: inline-flex;
    background-color: #21262d; /* GitHub dark button background */
    color: #c9d1d9;
    padding: 0.75rem 1.5rem;
    border-radius: 4px;
    text-decoration: none;
    transition: background-color 0.3s;
    align-items: center;
    justify-content: center;
}

.download-resume a:hover {
    background-color: #30363d; /* GitHub dark button hover */
}

.download-resume img {
    width: 20px; /* Adjust the size as needed */
    height: auto;
    margin-right: 0.5rem;
}

