#projects {
  background-color: #0d1117; /* GitHub dark background */
  color: #c9d1d9; /* GitHub dark text color */
  padding: 2rem 5%;
  text-align: center;
  padding-top: 10rem;
}

#projects h3 {
  font-size: 2rem;
  margin-bottom: 2rem;
  color: #58a6ff; /* GitHub dark blue color for titles */
}

.project-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
}

.project {
  width: calc(33.333% - 1rem); /* Three items per row */
  margin-bottom: 2rem;
  background: #161b22; /* Slightly lighter bg for the project cards */
  padding: 1rem;
  border-radius: 6px;
  box-shadow: 0 8px 24px rgba(0,0,0,0.6);
}

.project h4 {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
  color: #58a6ff; /* Title color */
}

.project p {
  font-size: 1rem;
  line-height: 1.5;
  padding-bottom: 1rem;
}

@media (max-width: 768px) {
  .project {
    width: calc(50% - 1rem); /* Two items per row for smaller screens */
  }
}

@media (max-width: 480px) {
  .project {
    width: 100%; /* Full width for very small screens */
  }
}

.technology-icon {
  height: 40px; /* Adjust the size as needed */
  margin: 0 5px; /* Add some space around the icons */
}
.image-container {
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
}

/* New Styles for Image Gallery */
.image-gallery {
  position: relative;
  width: 100%;
  text-align: center;
}

.image-gallery img {
  max-width: 100%;
  height: auto;
  display: block;
  margin: 0 auto;
}

.image-gallery button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.2); /* Subtle background */
  color: white;
  border: none;
  padding: 10px 15px;
  cursor: pointer;
  border-radius: 50%;
  font-size: 16px;
  z-index: 2;
  opacity: 0.5; /* Make button slightly visible */
  transition: background-color 0.3s, opacity 0.3s; /* Smooth transition for hover effect */
}

.image-gallery button:hover {
  background-color: rgba(0, 0, 0, 0.6); /* More visible on hover */
  opacity: 1;
}

.image-gallery button.left {
  left: 10px;
}

.image-gallery button.right {
  right: 10px;
}


@media (max-width: 768px) {
  .image-gallery button {
    padding: 5px 10px;
    font-size: 14px;
  }
}

/* Adjustments for Single Project Layout */
.project {
  width: 80%; /* Adjust for single project view */
  margin: 2rem auto;
}

@media (max-width: 768px) {
  .project {
    width: 90%; /* Wider on smaller screens */
  }
}

@media (max-width: 480px) {
  .project {
    width: 100%; /* Full width on very small screens */
  }
}
